import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const SocialLinks: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      github: file(relativePath: { regex: "/icons/github/" }) {
        ...iconFragment
      }
      twitter: file(relativePath: { regex: "/icons/twitter/" }) {
        ...iconFragment
      }
      linkedin: file(relativePath: { regex: "/icons/linkedin/" }) {
        ...iconFragment
      }
    }

    fragment iconFragment on File {
      childCloudinaryAsset {
        fluid(maxWidth: 100) {
          ...CloudinaryAssetFluid
        }
      }
    }
  `);
  const links = [
    {
      name: "twitter",
      url: "https://twitter.com/jzhang729",
      newWindow: true
    },
    {
      name: "github",
      url: "https://github.com/jzhang729",
      newWindow: true
    },
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/jordanzhang",
      newWindow: true
    }
  ];

  const renderLinks = () => {
    return links.map(link => (
      <div className="social__link" key={link.name}>
        <a
          href={link.url}
          target={link.newWindow ? "_blank" : ""}
          rel="noopener"
        >
          <Img
            className={`social__icon--${link.name}`}
            fluid={data[`${link.name}`].childCloudinaryAsset.fluid}
            alt={link.name}
            title={link.name}
          />
        </a>
      </div>
    ));
  };

  return (
    <div id="social">
      <div className="social__links">{renderLinks()}</div>
    </div>
  );
};

export default SocialLinks;
