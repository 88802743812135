import React, { FormEvent, useState, useEffect } from "react";
import Layout from "../components/Layout";
import SocialLinks from "../components/SocialLinks";
import Seo from "../components/Seo";
import Recaptcha from "react-recaptcha";

const ContactPage: React.FC = () => {
  const [isFormShowing, setIsFormShowing] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const [valid, setValid] = useState(false);

  const messages = {
    success: "Thanks! I'll get back to you as soon as I can.",
    error: "Oops! Looks like something went wrong."
  };

  useEffect(() => {
    if (checkValid()) {
      setShowRecaptcha(true);
    } else {
      invalidateForm();
    }
  }, [name, email, message]);

  useEffect(() => {
    if (recaptchaResponse && checkValid()) {
      setValid(true);
    } else {
      invalidateForm();
    }
  }, [recaptchaResponse]);

  const invalidateForm = (): void => {
    setShowRecaptcha(false);
    setValid(false);
  };

  const checkValid = (): boolean => {
    return name && email && message ? true : false;
  };

  const verifyCallback = (response: string): void => {
    if (!response) {
      throw new Error("No response");
    }

    setRecaptchaResponse(response);

    // TODO: Send the response to the serverless function

    // const res = await fetch("https://www.google.com/recaptcha/api/siteverify", {
    // method: "POST",
    // headers: {
    // "Content-Type": "application/json"
    // }
    // body: {}
    // });

    // if (contactForm) {
    //   contactForm.submit();
    // }
  };

  const handleClearForm = (): void => {
    setName("");
    setEmail("");
    setMessage("");
    setRecaptchaResponse("");
    invalidateForm();
  };

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();

    const res = await fetch(
      "https://k399zrtq4g.execute-api.us-east-1.amazonaws.com/dev/email/send",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: email,
          name: name,
          content: message
        })
      }
    )
      .then(res => {
        if (res.status === 200) {
          handleClearForm();
          setIsFormShowing(false);
        }
      })
      .catch(err => {
        throw new Error(`An error occurred: ${err}`);
      });

    return res;
  };

  const renderForm = () => {
    if (!isFormShowing) {
      return <p>{messages.success}</p>;
    }

    return (
      <form
        id="contact-form"
        className="contact"
        // ref={e => (contactForm = e)}
        onSubmit={e => {
          handleSubmit(e);
        }}
      >
        <div>
          <label htmlFor="name">
            <span>Name</span>
            <input
              id="name"
              type="text"
              name="name"
              placeholder="Name"
              required
              onChange={e => setName(e.target.value)}
              tabIndex={0}
              value={name}
            />
          </label>
        </div>

        <div>
          <label htmlFor="email">
            <span>Email</span>
            <input
              id="email"
              type="email"
              name="email"
              placeholder="Email"
              onChange={e => setEmail(e.target.value)}
              required
              tabIndex={0}
              value={email}
            />
          </label>
        </div>
        <div>
          <label htmlFor="content">
            <span>Message</span>
            <textarea
              id="content"
              name="content"
              rows={5}
              required
              onChange={e => setMessage(e.target.value)}
              placeholder="Send me a message here."
              tabIndex={0}
              value={message}
            />
          </label>
        </div>
        {showRecaptcha && (
          <div className="contact__recaptcha">
            <Recaptcha
              render="explicit"
              sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
              verifyCallback={verifyCallback}
            />
          </div>
        )}

        <div className="contact__actions">
          <button
            className="contact__actions--primary"
            tabIndex={0}
            type="submit"
            disabled={!valid}
          >
            Submit
          </button>
          <input
            type="button"
            value="Clear"
            className="contact__actions--secondary"
            tabIndex={0}
            onClick={handleClearForm}
          />
        </div>
      </form>
    );
  };

  return (
    <Layout>
      <Seo title="contact" />
      <SocialLinks />
      {renderForm()}
    </Layout>
  );
};

export default ContactPage;
